const planConfig = [
    {
        CLIENT_ID : '5448',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5449',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5514',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5515',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4350',
        iframeURL : 'https://www.universalhealthfellowship.org/uhs-afa-program-grid/'
    },
    {
        CLIENT_ID : '4429',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4428',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '5446',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '5688',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4395',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5703',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4401',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5704',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4403',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5705',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4402',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5711',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4404',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5689',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4410',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5735',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5737',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4411',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5738',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4412',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5739',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4413',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5743',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4600',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7701',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6550',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7702',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6551',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7703',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6552',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7705',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6556',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7706',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6557',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '7707',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6558',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4396',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5702',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4399',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5447',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '5450',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '5503',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '6548',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '4364',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5540',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '5541',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '4386',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '5558',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '4397',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '5696',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '5596',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '4390',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
	{
        CLIENT_ID : '4376',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
	{
        CLIENT_ID : '4377',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '5550',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },    
    {
        CLIENT_ID : '4378',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5553',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4379',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5559',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '5647',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4391',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4360',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4358',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4365',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4366',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4354',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4355',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4357',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4356',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4359',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-2-4-6.pdf'
    },
    {
        CLIENT_ID : '4367',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf'
    },
    {
        CLIENT_ID : '4387',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID : '4388',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf'
    },
    {
        CLIENT_ID: '5742',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Senior-65-Brochure-Grid-(Phase-1).pdf'
    },
    {
        CLIENT_ID: '4599',
        iframeURL : 'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Senior-65-Brochure-Grid-(Phase-1).pdf'
    }
]

export default planConfig;